/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:b94e2841-2344-42ec-abb4-db502673c0b5",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_tnYBBfGGz",
    "aws_user_pools_web_client_id": "1rdmd2im0r7fgq8qimvge26b7v",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://53zwo37zzbdjpgu36ce5selumu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-zoxpfxynejh2vhk2hjfaafj42i",
    "aws_user_files_s3_bucket": "jrwright12140c655c3241441ab9a3f7b283240d5f8225844-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
